import React from 'react';
import { Formik } from 'formik';
import StrategyTitleBlock from '../strategyTitleBlock/StrategyTitleBlock';
import SingleCasesForm from './SingleCasesForm';
import {
  singleCasesSettingsValidation,
  TOTAL_STEPS,
  STRATEGY_STEPS_LABELS,
  StrategySettingsFormTypes
} from 'constants/strategyProfilesAddConstants';
import s from './SingleCases.module.scss';
import {
  AddSPErrors,
  SCTypeChoice,
  SingleCasesSettings,
  SingleCasesValues,
  StrategyProfileSettingsShape
} from 'interfaces/strategyProfilesShapes';
import { FormTarget } from 'constants/strategyProfilesConstants';

export interface singleCasesProps {
  onSubmitSingleCases: (values, type) => void;
  onClearRequestErrors: () => void;
  onCancel: () => void;
  profile: StrategyProfileSettingsShape | null;
  errors: AddSPErrors;
  target?: FormTarget;
  isStepsHidden?: boolean;
  contentWrapperClass?: string;
}

const SingleCases = ({
  target,
  onSubmitSingleCases,
  onCancel,
  profile,
  errors: { profileErr },
  isStepsHidden
}: singleCasesProps): JSX.Element => {
  const singleCasesSettings = profile?.settings?.single_cases_settings;

  const initialValues: SingleCasesValues = {
    ...singleCasesSettings,
    sc_mm_decrease: singleCasesSettings?.sc_type === SCTypeChoice.SC_MM,
    sc_skip: singleCasesSettings?.sc_type === SCTypeChoice.SC_SKIP
  };

  const onSubmit = (values: SingleCasesValues, setSubmitting: (isSubmitting: boolean) => void) => {
    const { is_enabled, sc_mm_decrease, sc_skip, signals_amount, mm_percent } = values;

    const scTypeMap: Record<string, SCTypeChoice | undefined> = {
      SC_MM: sc_mm_decrease ? SCTypeChoice.SC_MM : undefined,
      SC_SKIP: sc_skip ? SCTypeChoice.SC_SKIP : undefined
    };

    const sc_type = scTypeMap.SC_MM || scTypeMap.SC_SKIP;

    const formatedValues: SingleCasesSettings = {
      is_enabled,
      signals_amount: signals_amount ? Number(signals_amount) : undefined,
      mm_percent: sc_mm_decrease && mm_percent ? Number(mm_percent) : undefined,
      sc_type
    };

    onSubmitSingleCases(formatedValues, StrategySettingsFormTypes.S_C_SETTINGS);
    setSubmitting(false);
  };

  return (
    <div className={s.wrapper}>
      <StrategyTitleBlock
        step={STRATEGY_STEPS_LABELS.SINGLE_CASES}
        totalSteps={TOTAL_STEPS}
        title="Single Cases"
        isStepsHidden={isStepsHidden}
      />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={singleCasesSettingsValidation}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <SingleCasesForm
            onCancel={onCancel}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            profileErr={profileErr}
            target={target}
          />
        )}
      </Formik>
    </div>
  );
};

export default SingleCases;
