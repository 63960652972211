import React, { FC } from 'react';
import cn from 'classnames';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import { Form } from 'formik';
import { ENABLE, SC_SKIP, SC_MM, MM_PERCENT, SIGNALS_AMOUNT } from 'constants/strategyProfilesAddConstants';
import s from './SingleCases.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';
import NumberFormatField from 'components/fields/numberFormatField/NumberFormatField';
import { FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { useSingleCases } from './useSingleCases';
import { singleCasesProps } from './SingleCases';

type SingleCasesFormProps = Pick<singleCasesProps, 'onCancel' | 'target'> & {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  isSubmitting: boolean;
  profileErr: null | string;
};

const SingleCasesForm: FC<SingleCasesFormProps> = ({ onCancel, handleSubmit, isSubmitting, profileErr, target }) => {
  const {
    enabled,
    scSkip,
    mmSkip,
    signalsAmount,
    mMPErcent,
    handleEnableChange,
    handleChange,
    handleBlur,
    setFieldValue,
    getIsError,
    getErrorMsg,
    getIsValid,
    errors,
    submitCount
  } = useSingleCases();

  return (
    <Form className={s.form}>
      <Box>
        <FormControlLabel
          className={s.bottom}
          label="Enable"
          control={
            <Checkbox
              color="primary"
              className={s.scCheck}
              name={ENABLE}
              checked={enabled}
              onChange={handleEnableChange}
            />
          }
        />

        {enabled && (
          <Box>
            {submitCount > 0 && errors.scSelection && (
              <span className={cn(s.error, 'errorText')}>{errors.scSelection}</span>
            )}
            <FormControlLabel
              className={s.bottom}
              label="SC-Skip"
              control={
                <Checkbox
                  color="primary"
                  className={s.scCheck}
                  checked={scSkip}
                  name={SC_SKIP}
                  onChange={(e) => setFieldValue(SC_SKIP, e.target.checked)}
                  disabled={mmSkip}
                />
              }
            />
            {errors[SC_SKIP] && <span className={cn(s.error, 'errorText')}>{errors[SC_SKIP]}</span>}

            <FormControlLabel
              className={s.bottom}
              label="SC-MM"
              control={
                <Checkbox
                  color="primary"
                  className={s.scCheck}
                  checked={mmSkip}
                  name={SC_MM}
                  onChange={(e) => setFieldValue(SC_MM, e.target.checked)}
                  disabled={scSkip}
                />
              }
            />
            {errors[SC_MM] && <span className={cn(s.error, 'errorText')}>{errors[SC_MM]}</span>}
          </Box>
        )}

        {enabled && mmSkip && (
          <FormControlLabel
            label={''}
            className={s.formControl}
            control={
              <NumberFormatField
                inputProps={{
                  id: MM_PERCENT,
                  label: 'MM percent',
                  placeholder: '...',
                  value: mMPErcent,
                  isError: getIsError(MM_PERCENT),
                  isValid: getIsValid(MM_PERCENT),
                  helperText: getErrorMsg(MM_PERCENT),
                  onBlur: handleBlur,
                  onChange: handleChange
                }}
              />
            }
          />
        )}
        {enabled && (mmSkip || scSkip) && (
          <FormControlLabel
            label=""
            className={s.formControl}
            control={
              <NumberFormatField
                inputProps={{
                  id: SIGNALS_AMOUNT,
                  label: 'Signals amount',
                  placeholder: '...',
                  value: signalsAmount,
                  isError: getIsError(SIGNALS_AMOUNT),
                  isValid: getIsValid(SIGNALS_AMOUNT),
                  helperText: getErrorMsg(SIGNALS_AMOUNT),
                  onBlur: handleBlur,
                  onChange: handleChange
                }}
              />
            }
          />
        )}

        <PairedBtnBlock
          onCancel={onCancel}
          onSubmit={handleSubmit}
          labels={{ cancelLabel: 'Cancel', submitLabel: target === FormTarget.EDITING ? 'Save' : 'Next' }}
          disableSubmit={isSubmitting}
          disableCancel={isSubmitting}
        />

        {profileErr && <span className={cn(s.error, 'errorText')}>{profileErr}</span>}
      </Box>
    </Form>
  );
};

export default SingleCasesForm;
